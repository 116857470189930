import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
  },
  palette: {
    // MUIテーマの上書き
    primary: {
      main: '#01A63B',
    },
    secondary: {
      main: '#C9E4BA',
    },
    // MUIテーマの新規作成
    main: {
      main: '#FF8298',
      primary: '#01A63B',
      secondary: '#C9E4BA',
      highLight: '#EFF7EA',
      white: '#fff',
      black: '#363636',
    },
    neutral: {
      main: '#FF8298',
      dark: '#161616',
      gray: '#B2B2B2',
      lightGray: '#EFEFEF',
      darkGray: '#848484',
      white: '#FFFFFF',
    },
  },
  // 源ノ角ゴシック(AdobeFonts)の導入よくわからず、似ているGoogle Fontsで代用。NextのFont OptimizationはAdobe Fonts非対応か？
  typography: {
    fontFamily: ['Zen Kaku Gothic New'].join(','),
  },
})

declare module '@mui/material/styles' {
  // パレット名
  interface Palette {
    main: Palette['primary']
    neutral: Palette['primary']
  }
  // allow configuration using `createTheme`
  interface PaletteOptions {
    main?: PaletteOptions['primary']
    neutral?: PaletteOptions['primary']
  }

  // カラー名
  interface SimplePaletteColorOptions {
    primary?: string
    secondary?: string
    highLight?: string
    gray?: string
    lightGray?: string
    darkGray?: string
    white?: string
    black?: string
  }
  interface PaletteColor {
    primary: string
    secondary: string
    highLight: string
    gray: string
    lightGray: string
    darkGray?: string
    white: string
    black: string
  }
}

export default theme
